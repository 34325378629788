<template>
  <Container size="small" class="link-list">
    <NuxtLink v-for="link in links" :key="link.id" :to="link.to" class="link">
      {{ link.text }}
    </NuxtLink>
  </Container>
</template>

<script>
  import { AlchemyElement, getElementsIngredient } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      links() {
        return this.element.nestedElements.map((element) => {
          const link = getElementsIngredient(element, "link")
          return {
            id: element.id,
            text: link.value,
            to: link.linkUrl,
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .link-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .link {
    display: inline-block;
    margin: 0 $space-s;
    color: $yellow-text;
    font-weight: bold;
    text-decoration: none;
    @apply text-lg;

    &[href]:hover {
      text-decoration: underline;
    }
  }
</style>
